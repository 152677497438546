import React, {FunctionComponent, ReactNode, useEffect, useState} from 'react';
import './CallToActionButton.scss';

const CallToActionButton: FunctionComponent<{
    text?: string;
    className?: string;
    action?: CallableFunction;
    color?: string;
    children?: ReactNode;
}> = ({text, className, action, color, children, ...otherProps}) => {
    const [btnColor, setBtnColor] = useState<string>(null);
    console.log(color)
    useEffect(() => {
        switch (color) {
            case 'brown':
                setBtnColor('brown-cta');
                break;
            case 'disable':
                setBtnColor('disable-cta');
                break;
            case 'green':
                setBtnColor('green-cta');
                break;
            case 'darkBrown':
                setBtnColor('darkBrown-cta');
                break;
            case 'shadow':
                setBtnColor('shadow-cta');
                break;
            case 'orange':
                setBtnColor('orange-cta');
                break;
            case 'black':
                setBtnColor('real-black-cta');
                break;
            case 'white':
                setBtnColor('white-cta');
                break;
            case 'incenseVerOrange':
                setBtnColor('incenseVerOrange');
                break;
            default:
                setBtnColor(null);
        }
    }, [color]);

    return (
        <button
            className={`cta-btn
            ${btnColor ? btnColor : ''}
            ${className ? className : ''} `}
            style={{width: '100%'}}
            onClick={() => {
                action && action();
            }}
            {...otherProps}
        >
            {text && text}
            {children && children}
        </button>
    );
};

export default CallToActionButton;
